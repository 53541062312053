import { SplashScreen } from "@capacitor/splash-screen";

export default defineNuxtPlugin(() => {
  onNuxtReady(async () => {
    SplashScreen.hide();
  });
  // Always hide splash screen after 30 seconds as a fallback
  window.setTimeout(() => {
    SplashScreen.hide();
  }, 30000)
});
